import jsSHA from 'jssha'
import aesjs from 'aes-js';
import { MXA_VERSION } from './Connection';
import crypto from 'crypto'

const IV_SIZE = 12;

interface CustomWindow {
    protocol: string,
    customCrypto?: typeof crypto
}

export class Crypto {
    
    static AesEncrypt(key: string, data: string) {
        return MXA_VERSION < 370 ? Crypto.AesEncryptCbc(key, data) : Crypto.AesEncryptGcm(key, data)
    }

    static AesDecrypt(key: string, data: string) {
        return MXA_VERSION < 370 ? Crypto.AesDecryptCbc(key, data) : Crypto.AesDecryptGcm(key, data)
    }

    // <summary>
    // Encrypt a block using CBC and PKCS7.
    // </summary>
    // <param name="key">The key value</param>
    // <param name="data">The message to encrypt</param>
    // <returns>Returns the resulting encrypted string data as HEX.</returns>
    static AesEncryptCbc(key: string, data: string) {
        const bytes = aesjs.utils.hex.toBytes(key);
        const iv = [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00 ];
        const textBytes = aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(data));
        const aesCbc = new aesjs.ModeOfOperation.cbc(bytes, iv);
        const encryptedBytes = aesCbc.encrypt(textBytes);
        const encryptedString = aesjs.utils.hex.fromBytes(encryptedBytes);
        return encryptedString;
    }

    // <summary>
    // Encrypt a block using AES/GCM.
    // </summary>
    // <param name="key">The key value</param>
    // <param name="data">The message to encrypt</param>
    // <returns>Returns the resulting encrypted string data as HEX.</returns>
    static AesEncryptGcm (key: string, data: string) {  
        const iv = new Uint8Array(IV_SIZE);
        crypto.getRandomValues(iv);
       
        const cipher = crypto.createCipheriv('aes-256-gcm', Crypto.hexStringToByteArray(key), iv);
        let encryptedData = cipher.update(data, 'utf8', 'hex');
        encryptedData += cipher.final("hex")
        
        return aesjs.utils.hex.fromBytes(iv) + encryptedData + cipher.getAuthTag().toString('hex')
    }
    
    // <summary>
    // Decrypt a block using a CBC and PKCS7.
    // </summary>
    // <param name="key">The key value</param>
    // <param name="data">the data to decrypt</param>
    // <returns>Returns the resulting data decrypted in plaintext.</returns>
    static AesDecryptCbc(key: string, data: string) {
        const bytes = aesjs.utils.hex.toBytes(key);
        const iv = [0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00 ];
        const encryptedBytes = aesjs.utils.hex.toBytes(data);
        const aesCbc = new aesjs.ModeOfOperation.cbc(bytes, iv);
        const decryptedBytes = aesCbc.decrypt(encryptedBytes);
        const decrypted = aesjs.utils.utf8.fromBytes(aesjs.padding.pkcs7.strip(decryptedBytes));
    
        return decrypted;
    }

    // <summary>
    // Decrypt a block using AES/GCM.
    // </summary>
    // <param name="key">The key value</param>
    // <param name="data">the data to decrypt</param>
    // <returns>Returns the resulting data decrypted in plaintext.</returns>
    static AesDecryptGcm(key: string, encryptedData: string) {
        const iv = encryptedData.slice(0, IV_SIZE * 2);
        const tag = encryptedData.slice(-32); 
        const encMessage = encryptedData.slice(IV_SIZE * 2, -32)

        const decipher = crypto.createDecipheriv('aes-256-gcm', Crypto.hexStringToByteArray(key), Crypto.hexStringToByteArray(iv));
        decipher.setAuthTag(Crypto.hexStringToByteArray(tag));
        let decrypted = decipher.update(encMessage, 'hex', 'utf8');
        decrypted += decipher.final();

        return decrypted
    }

    // <summary>
    // Calculates the HMACSHA256 signature of a message.
    // </summary>
    // <param name="key">The Hmac Key as HEX</param>
    // <param name="messageToSign">The message to sign</param>
    // <returns>The HMACSHA256 signature as a hex string</returns>
    static HmacSignature(key: string, messageToSign: string) {
        const shaObj = new jsSHA("SHA-256", "TEXT");

        shaObj.setHMACKey(key,'HEX');
        shaObj.update(messageToSign);
        
        return shaObj.getHMAC("HEX");
    }


    /**
     * This utility function calculates the SHA-256 value in hexadecimal format
     * @param {String} value the value to be hashed
     */
    static GenerateHash(value: string) {
        const shaObj = new jsSHA('SHA-256', 'HEX');
        shaObj.update(value);
        const shaHash = shaObj.getHash('HEX');
        return shaHash;
    }

    static hexStringToByteArray(hex: string) {
        const len = hex.length;
        const data = new Uint8Array(len / 2);
        let i = 0;
        while (i < len) {
          data[i / 2] = ((parseInt(hex[i], 16) << 4) +
                         parseInt(hex[i + 1], 16)) & 0xff;
          i += 2;
        }
        return data;
    }

    static byteArrayToHexString(ba: Uint8Array): string {
        const hexChars = new Array(ba.length * 2);
        for (let j = 0; j < ba.length; j++) {
          const v = ba[j] & 0xff;
          hexChars[j * 2] = "0123456789ABCDEF"[v >> 4];
          hexChars[j * 2 + 1] = "0123456789ABCDEF"[v & 0x0f];
        }
        return hexChars.join("");
    }

    static generateRandomInt(max: number) {
        // This is a hack to make randomBytes function available in Spice
        // when we use the crypto module together with a polyfill in webpack, it's expected to work fine in both browser and node env
        // but when Spice (node) uses this function, it throws an error because we made a custom window object in Spice (for other purposes)
        // and that makes the polyfill think that this code is ran in the browser, hence it'll use the polyfilled version of crypto. 
        // => error. 
        const customWindow = window as unknown as CustomWindow
        const randomBytes = customWindow.customCrypto ? customWindow.customCrypto.randomBytes(4) : crypto.randomBytes(4);
        const randomValue = randomBytes.readUInt32BE(0);
        return randomValue % max;
    }
}

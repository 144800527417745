import {Message, Events, SuccessState, Data} from './Messages';
import {RequestIdHelper} from './RequestIdHelper';

type OtherInfo = {
    spice_version: string
} & DeviceInfo

interface SetPosInfoRequestData extends Data {
    pos_version: string
    pos_vendor_id: string
    library_language: string
    library_version: string
    spice_version: string
    other_info: OtherInfo
}
export class SetPosInfoRequest
{
	public _version: string;
	public _vendorId: string;
	public _libraryLanguage: string;
	public _libraryVersion: string;
	public _spiceVersion: string;
	public _otherInfo: object;

    constructor(version: string, vendorId: string, spiceVersion: string, libraryLanguage: string, libraryVersion: string, otherInfo: DeviceInfo)
    {
        this._version = version;
        this._vendorId = vendorId;
        this._libraryLanguage = libraryLanguage;
        this._libraryVersion = libraryVersion;
        this._spiceVersion = spiceVersion;
        this._otherInfo = otherInfo;
    }

    toMessage()
    {
        const data = {
            pos_version: this._version,
            pos_vendor_id: this._vendorId,
            library_language: this._libraryLanguage,
            library_version: this._libraryVersion,
            spice_version: this._spiceVersion,
            other_info: Object.assign({}, this._otherInfo, { spice_version: this._spiceVersion })
        };

        return new Message<SetPosInfoRequestData>(RequestIdHelper.Id("prav"), Events.SetPosInfoRequest, data, true);
    }
}

export class SetPosInfoResponse
{
	public _success: boolean;
	public _m: Message<Data>;

    constructor(m: Message<Data>)
    {
        this._success = m.GetSuccessState() == SuccessState.Success;
        this._m = m;
    }
    isSuccess()
    {
        return this._success;
    }
    getErrorReason()
    {
        return this._m.Data.error_reason;
    }
    getErrorDetail()
    {
        return this._m.Data.error_detail;
    }
    getResponseValueWithAttribute(attribute: string): unknown
    {
        return this._m.Data[attribute];
    }
}

export interface DeviceInfo {
    device_system?: string
}
export class DeviceInfo
{
    static GetAppDeviceInfo()
    {
        const deviceInfo: DeviceInfo = {};
        deviceInfo['device_system'] = navigator.userAgent;
        // deviceInfo.Add("device_system", Environment.OSVersion.Platform.ToString() + " " + Environment.OSVersion.Version.ToString());
        return deviceInfo;
    }
}

import BN from 'bn.js';
import { Crypto } from './Crypto';

// This creates the private and public keys for diffie-hellman (https://en.wikipedia.org/wiki/Diffie%E2%80%93Hellman_key_exchange#Cryptographic_explanation)
// REQUIREMENTS: bn.js
// ASSUMPTIONS: Inputs to the functions are hexadecimal strings

// <summary>
// This class implements the Diffie-Hellman algorithm using BigIntegers.
// It can do the 3 main things:
// 1. Generate a random Private Key for you.
// 2. Generate your Public Key based on your Private Key.
// 3. Generate the Secret given their Public Key and your Private Key
// p and g are the shared constants for the algorithm, aka primeP and primeG.
// </summary>
export class DiffieHellman {
    private _randomBigInt(maxValue: BN) {
        const maxIntString = maxValue.toString(10)
        let randomIntString = '';
    
        let below = false;
    
        for (let i = 0; i < maxIntString.length; i++) {
            const thisDigit = parseInt(maxIntString[i]);
    
            let maxDigit: number;
            if (!below) {
                maxDigit = thisDigit;
            } else {
                maxDigit = 9;
            }
    
            let rDigit = Crypto.generateRandomInt(10)
            if (rDigit > maxDigit) {
                rDigit = 0;
            }
            randomIntString += rDigit.toString();
            if (rDigit < maxDigit) {
                below = true;
            }
        }
    
        return new BN(randomIntString);
    }
  

    // <summary>
    // Generates a random Private Key that you can use.
    // </summary>
    // <param name="p"></param>
    // <returns>Random Private Key</returns>
    public RandomPrivateKey(maxValue: string) {
        const randBitInt      = this._randomBigInt(new BN(maxValue, 16).subn(1));
        const min             = new BN(2);

        return randBitInt.cmp(min) == -1 ? min : randBitInt;
    }

    // <summary>
    // Calculates the Public Key from a Private Key.
    // </summary>
    // <param name="p"></param>
    // <param name="g"></param>
    // <param name="privateKey"></param>
    // <returns>Public Key (Hex)</returns>
    public PublicKey(p: string, g: number, privateKey: BN) {
        const aHex = new BN(privateKey, 16);
        const gHex = new BN(g, 16);
        const montPrime = BN.mont(new BN(p, 16));
        const gRed = gHex.toRed(montPrime);
        const secret = gRed.redPow(aHex).fromRed().toString(16);

        return secret;  
    }

    // <summary>
    // Calculates the shared secret given their Public Key (A) and your Private Key (b)
    // </summary>
    // <param name="p"></param>
    // <param name="theirPublicKey"></param>
    // <param name="yourPrivateKey"></param>
    // <returns></returns>
    public Secret(p: string, theirPublicKey: string, yourPrivateKey: BN) {        
        const bHex = new BN(theirPublicKey, 16);
        const AHex = new BN(yourPrivateKey, 16);
        const montPrime = BN.mont(new BN(p, 16));
        const BRed = bHex.toRed(montPrime);

        if (bHex.cmp(new BN(2)) === -1) {
            throw new Error("Public key cannot less than 2")
        }
        
        return BRed.redPow(AHex).fromRed().toString(16).toUpperCase();
    }
}

import { StringOrNumber } from "./types";

export class Logger {
    element: HTMLElement
    buffer: string[]
    lineSeperator: string

    constructor(element: HTMLElement, lineSeperator = '\n') {
        this.buffer = [];
        this.element = element;
        this.lineSeperator = lineSeperator;
    }

    Info(...args: Array<StringOrNumber>) {
        this.buffer.push(args.join(' '));
        this._render();
    }

    Debug(...args: Array<StringOrNumber>) {
        this.buffer.push(args.join(' '));
        this._render();
    }

    Warn(...args: Array<StringOrNumber>) {
        this.buffer.push(args.join(' '));
        this._render();
    }

    Error(...args: Array<StringOrNumber>) {
        this.buffer.push(args.join(' '));
        this._render();
    }

    Console(...args: Array<StringOrNumber>) {
        console.log(args.join(' '));
    }

    _render() {
        this.element.innerText = this.buffer.join(this.lineSeperator);
        this.element.scrollTop = this.element.scrollHeight;
    }

    Clear() {
        this.buffer = [];
        this._render();
    }
}
